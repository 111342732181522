import { Bill } from "./interfaces/Models";
import { EventFrequency } from "../components/interfaces/EventFrequency";
import { AUDollar } from "../service/functions/moneyFormat";
import { ChangeEvent, useState } from "react";
import { TextField, Button, InputLabel, FormLabel, Grid } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { enAU } from 'date-fns/locale/en-AU';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Container } from "@mui/system";
import { useHistory } from "react-router-dom";
import { WithHook } from "../service/hooks/withHook";
import { AmountPerPeriodForBill } from "./functions/AmountPerPeriodForBills";
import { DatePicker } from "../components/DatePicker";
import { SelectWithAdd } from "../components/SelectWithAdd";
import { useSettings } from "../service/hooks/useSettings";
import { useBills } from "../service/hooks/useBills";
import { NumericFormat } from "react-number-format";
import { moneyFormat } from "../components/MoneyFormat";

export const BillDetail = (props: {
    onGoBack: () => void
}) => {
    const history = useHistory()

    const params = new URLSearchParams(history.location.search)

    const { settings } = useSettings()

    const { data, stage, isLoading } = useBills()

    const [billData, setBillData] = useState<Bill>(newIBillState(data.bills.find((b) => b.id === params.get("bills"))))

    const [categories, setCategories] = useState<string[]>(data.bills.filter(b => b.category != null).map(b => b.category as string) || [])

    function onStage(cmd: Bill) {
        let copy = [...data.bills];
        const idx = copy.findIndex(b => b.id === params.get("bills"));
        if (idx === -1) {
            const id = new Date().getTime().toString()
            copy.push({ ...cmd, id: id, amount: Number(cmd.amount) })
            stage(copy)
        } else {
            copy.splice(idx, 1)
            copy.push({ ...cmd, amount: Number(cmd.amount || 0) })
            stage(copy)
        }
        props.onGoBack()
    }

    function onDelete() {
        let copy = [...data.bills];
        const idx = copy.findIndex(b => b.id === params.get("bills"));
        if (idx === -1) {
            return
        } else {
            copy.splice(idx, 1)
            stage(copy)
        }
    }

    function onChangeBillName(e: ChangeEvent<HTMLInputElement>) {
        const d = e.currentTarget.value;
        setBillData(prev => {
            return { ...prev, name: d };
        });
    }

    function onChangeFrequency(e: SelectChangeEvent<"Week" | "Month" | "Fortnight" | "Year" | "Half-Year" | "Quarter">) {
        const d = e.target.value as EventFrequency;
        setBillData(prev => {
            return { ...prev, frequency: d };
        });
    }

    function onChangeNextDue(value: Date | null) {
        if (value == null) {
            return;
        }
        setBillData(prev => {
            return { ...prev, nextDue: value };
        });
    }

    function onChangeCategory(i: string) {
        setBillData(prev => {
            return { ...prev, category: i };
        });
    }

    function onChangeAmount(e: ChangeEvent<HTMLInputElement>) {
        const d = e.currentTarget.value;

        setBillData(prev => {
            return { ...prev, amount: parseFloat(d) };
        })
    }

    function newIBillState(bill?: Bill): Bill {
        return {
            id: bill?.id ?? new Date().getTime().toString(),
            name: bill?.name ?? "",
            frequency: bill?.frequency ?? "Fortnight",
            nextDue: bill?.nextDue ?? new Date(),
            amount: bill?.amount ?? 0
        }
    }

    return <WithHook isLoading={isLoading}>
        <Container maxWidth={"md"}>
            <Grid container gap={4} direction={"column"}>
                <h3>Bill Details</h3>
                <Grid>
                    <FormControl fullWidth={true}>
                        <TextField
                            key={"billName"}
                            label={"Bill Name"}
                            placeholder={"Enter Bill Name"}
                            autoComplete={"none"}
                            onChange={onChangeBillName}
                            value={billData.name} />
                    </FormControl>
                </Grid>
                <Grid>
                    <FormControl fullWidth={true}>
                        <InputLabel id="frequency-select-label">Frequency</InputLabel>
                        <Select fullWidth={true}
                            labelId="frequency-select-label"
                            id="frequency-select"
                            label={"Frequency"}
                            onChange={(i) => onChangeFrequency(i)}
                            value={billData.frequency}>
                            <MenuItem value={"Week"} contextMenu="">Week</MenuItem>
                            <MenuItem value={"Fortnight"}>Fortnight</MenuItem>
                            <MenuItem value={"Month"}>Month</MenuItem>
                            <MenuItem value={"Quarter"}>Quarter</MenuItem>
                            <MenuItem value={"Half-Year"}>Half-Year</MenuItem>
                            <MenuItem value={"Year"}>Year</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid>
                    <FormControl fullWidth={true}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enAU}>
                            <DatePicker
                                value={billData.nextDue}
                                onChange={onChangeNextDue}
                                label="Next Due" />
                        </LocalizationProvider>
                    </FormControl>
                </Grid> 
                <Grid>
                    <FormControl fullWidth={true}>
                        <InputLabel id="category-select-label">Category</InputLabel>
                        <SelectWithAdd
                            setnewselectitem={(i) => {
                                setCategories(prev => {
                                    return Array.from(new Set([...prev, i]))
                                })
                                onChangeCategory(i)
                            }}
                            selectitems={categories} 
                            addlabel={"Add New Category"}
                            fullWidth={true}
                            labelId="category-select-label"
                            id="category-select"
                            label={"Category"}
                            onChange={(i) => onChangeCategory(i.target.value)}
                            value={billData.category ?? ""}/>
                    </FormControl>
                </Grid>
                <Grid>
                    <FormControl fullWidth={true}>
                        <NumericFormat
                            key={"amountDue"}
                            label={"Amount"}
                            placeholder={"Amount Due"}
                            autoComplete={"none"}
                            onChange={onChangeAmount}
                            value={billData.amount}
                            InputProps={moneyFormat}
                            customInput={TextField}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={","} />
                    </FormControl>
                </Grid>
                <Grid>
                    <FormLabel>Calculated Amount per Period:</FormLabel>
                    <div>{AUDollar.format(AmountPerPeriodForBill(settings.billPeriodDuration, billData.frequency, billData.amount) || 0)}</div>
                    <Grid container gap={2} direction={"row"}>
                        <Button
                            variant={"contained"}
                            onClick={() => onStage(billData)}>Done</Button>
                        <Button
                            variant={"contained"}
                            onClick={() => props.onGoBack()}>Cancel</Button>
                        <Button
                            variant={"contained"}
                            onClick={() => onDelete()}>Delete</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    </WithHook>
};