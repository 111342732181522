import { Backdrop, CircularProgress, Grid } from "@mui/material"

export const WithHook = (props: { children: JSX.Element, isLoading?: string, isError?: Error }) => {
    if (props.isLoading != null) {
        return <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center">
                <CircularProgress color="inherit" />
                <h3>{props.isLoading}</h3>
            </Grid> 
        </Backdrop>
    }

    if (props.isError) {
        return <div>
            Something went wrong and we were unable to fetch the data:
            {props.isError.message}
        </div>
    }

    return props.children
}
