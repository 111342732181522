import { EventFrequency } from "../../components/interfaces/EventFrequency";

export function offsetDateByFrequency(date: Date, frequency: EventFrequency, multiplier: number): Date {
    let r = new Date(date);
    switch (frequency) {
        case "Year":
            r.setFullYear(r.getFullYear() + (1 * multiplier));
            return r;
        case "Half-Year":
            r.setMonth(r.getMonth() + (6 * multiplier));
            return r;
        case "Quarter":
            r.setMonth(r.getMonth() + (3 * multiplier));
            return r;
        case "Month":
            r.setMonth(r.getMonth() + (1 * multiplier));
            return r;
        case "Fortnight":
            r.setDate(r.getDate() + (14 * multiplier));
            return r;
        case "Week":
            r.setDate(r.getDate() + (7 * multiplier));
            return r;
    }
}
