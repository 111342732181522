import {Grid, InputLabel, TextField} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {enAU} from "date-fns/locale/en-AU";
import {useSettings} from "../service/hooks/useSettings";
import { DatePicker } from "../components/DatePicker";

export const BudgetPeriods = () => {
    const { settings, setSettings } = useSettings();

    return <>
        <h3>My Budget App</h3>
        <Grid container direction={"row"} alignItems={"center"} gap={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enAU}>
                <DatePicker
                    value={settings.billPeriodStart}
                    onChange={(d) => setSettings({billPeriodStart: d ?? settings.billPeriodStart})}
                    label="Period Start" />
            </LocalizationProvider>
            <InputLabel>To</InputLabel>
            <TextField
                label={"Period End"}
                onChange={() => null}
                value={settings.billPeriodEnd.toLocaleDateString()} />
        </Grid>
    </>
}