import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useHistory } from 'react-router-dom';
import { Pending, Save, Undo } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { useCurrentBalances } from '../service/hooks/useCurrentBalances';
import { useBills } from '../service/hooks/useBills';
import { useContext } from 'react';
import { AppContext } from '../App';

export const ResponsiveAppBar = () => {
    const {appState : { staged }} = useContext(AppContext);

    const balances = useCurrentBalances()
    const bills = useBills()

    const auth = useAuthenticator(context => [context.user])

    const h = useHistory()

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const pages = [
        { title: 'Budget', action: () => h.push("/") },
        { title: 'Bills', action: () => h.push("/bills") },
        { title: 'Savings', action: () => h.push("/savings") },
    ];

    const settings = [
        { title: 'Quick Notes', action: () => h.push("/notes") },
        { title: 'Bills Importer', action: () => h.push("/bills/import") },
        { title: 'Settings', action: () => h.push("/settings") },
        { title: 'Sign Out', action: () => auth.signOut() }
    ]

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    }

    const handleCloseUserMenu = (then: () => void) => {
        setAnchorElUser(null);
        then()
    }

    const isLoading = balances.isLoading != null || bills.isLoading != null

    const isStaged = staged.bills || staged.currentBalances

    function onSave() {
        bills.save()
        balances.save()
    }

    function onRevert() {
        bills.revert()
        balances.revert()
    }

    return (<>
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <AdbIcon sx={{ mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            mr: 2,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}>
                        MyBudgetApp
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page.title}
                                onClick={() => page.action()}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page.title}
                            </Button>
                        ))}
                    </Box> 
                        <Tooltip title="Revert Changes">
                            <IconButton onClick={onRevert} >
                                <Avatar sx={{ bgcolor: isLoading ? '#bdbdbd' : green[500], display: isStaged ? 'flex' : 'none' }}>
                                    {isLoading ? <Pending/> : <Undo />}
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Commit Changes">
                            <IconButton onClick={onSave} >
                                <Avatar sx={{ bgcolor: isLoading ? '#bdbdbd' : green[500], display: isStaged ? 'flex' : 'none' }}>
                                    {isLoading ? <Pending/> : <Save />}
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Profile Options">
                                <IconButton onClick={handleOpenUserMenu} >
                                    <Avatar />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={() => handleCloseUserMenu(() => null)}
                            >
                                {settings.map((setting) => (
                                    <MenuItem
                                        key={setting.title}
                                        onClick={() => handleCloseUserMenu(setting.action)}>
                                        <Typography
                                            textAlign="center">
                                            {setting.title}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                </Toolbar>
            </Container>
        </AppBar>
    </>);
}