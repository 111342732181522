import { Note } from "../../notes/interfaces/Models"
import { useStore } from "../localstorage/store"

export const useNotes = () : {notes: Note[], setNotes: (notes: Note[]) => void} => {
    const store = useStore()

    const notes : Note[] = JSON.parse(store.get("notes") || "[]")

    const setNotes = (notes: Note[]) => { store.set("notes", JSON.stringify(notes)) }

    return {notes, setNotes}
}