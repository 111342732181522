import {fetchAuthSession} from "aws-amplify/auth";
import {api} from "./api";
import {Settings} from "../../settings/interfaces/Models";

export async function getSettings() : Promise<Settings> {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {}
    return api(accessToken?.toString()).get("/settings").then(res => res.data)
}

export async function saveSettings(cmd : Settings) {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {}
    return api(accessToken?.toString()).put("/settings", cmd).then(res => res)
}