import { useState } from "react";
import { Income } from "../../income/interfaces/Models";
import { daysPerPeriod } from "./daysPerPeriod";

export const useIncome = () => {
    const [incomeStreams] = useState<Income[]>([
        {
            id: new Date().getTime().toString(),
            frequency: "Fortnight",
            nextDue: new Date("2024-03-15"),
            name: "Chris' Income",
            amount: 2396.23
        },
        {
            id: new Date().getTime().toString(),
            frequency: "Fortnight",
            nextDue: new Date("2024-03-08"),
            name: "Caite's Income",
            amount: 2300.00
        }
    ])

    return {incomeStreams}
}

export const sortIncome = (income: Income[], orderBy: string, order: string): Income [] => {
    let sorted = [...income]

    switch (orderBy) {
        case "name":
            sorted = income.sort((a, b) => {
                const sort = a.name.localeCompare(b.name);
                return order === "asc" ? sort : -sort
            })
            break;
        case "frequency":
            sorted = income.sort((a, b) => {
                const sort = daysPerPeriod(a.frequency) > (daysPerPeriod(b.frequency)) ? 1 : -1
                return order === "asc" ? sort : -sort
            })
            break;
        case "dueDate":
            sorted = income.sort((a, b) => {
                const sort = a.nextDue.getTime() > b.nextDue.getTime() ? 1 : -1
                return order === "asc" ? sort : -sort
            })
            break;
        case "amount":
            sorted = income.sort((a, b) => {
                const sort = a.amount > b.amount ? 1 : -1
                return order === "asc" ? sort : -sort
            })
            break;
        default:
            break;
    }

    return sorted    
}

export const filterIncome = (income: Income[], filter: string | null): Income[] => {
    let filtered = [...income]
    if (filter != null) {
        filtered = income.filter(b => b.name.toLowerCase().includes(filter.toLowerCase()))
    }
    return filtered
}