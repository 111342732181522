import { useMutation, useQuery } from "@tanstack/react-query"
import { AnnualReserveForBills } from "../../bills/functions/AnnualReserveForBills"
import { RequiredBalanceForBills } from "../../bills/functions/RequiredBalanceForBills"
import { useCallback, useContext, useMemo } from "react"
import { getBills, saveBills } from "../api/bills"
import { Bill } from "../../bills/interfaces/Models"
import { AmountPerPeriodForBill } from "../../bills/functions/AmountPerPeriodForBills"
import { useSettings } from "./useSettings";
import { AppContext } from "../../App"
import { daysPerPeriod } from "../functions/daysPerPeriod"

export interface useBillsResponse {
    data: {
        bills: Bill[]
        billEvents: Bill[]
        requiredBalance: number
    }
    stage: (cmd: Bill[]) => void
    save: () => void
    revert: ()=>void
    isLoading?: string
}

export const useBills = (): useBillsResponse => {
    const { appState, setAppState } = useContext(AppContext)
    
    const {settings} = useSettings()

    const billQuery = useQuery({
        queryKey: ["get-bills"],
        queryFn: () => getBills(),
        staleTime: Infinity,
    })

    const billsMutation = useMutation({
        mutationKey: ["save-bills"],
        mutationFn: (cmd: Bill[]) => saveBills(cmd),
        onSuccess: (_, props) => {
            billQuery.refetch().then(res => {
                if (res.data != null) {
                    setAppState(prev => {return {...prev, bills: res.data, staged: {...prev.staged, bills: false} }})
                } else {
                    setAppState(prev => { return { ...prev, staged: {...prev.staged, bills: false} } })
                }
            })
        }
    })

    const stage = useCallback((s: Bill[]) => {
        setAppState(prev => {
            return {
                ...prev,
                staged: {...prev.staged, bills: true},
                bills: s
            }
        })
    }, [setAppState])

    const save = useCallback(() => {
        if (appState.bills != null) {
            billsMutation.mutate(appState.bills)
        }
    }, [billsMutation, appState.bills])

    const revert = useCallback(() => {
        setAppState(prev => {
            return {
                ...prev,
                staged: {...prev.staged, bills: false},
                bills: billQuery.data
            }
        })
    }, [billQuery, setAppState])

    let { bills, billEvents, requiredBalance } = useMemo(() => {
        let data: Bill[] | undefined
        if (appState.staged.bills && appState.bills != null) {
            data = appState.bills
        } else {
            data = billQuery.data
        }

        if (data == null) {
            return { bills: [], billEvents: [], requiredBalance: 0 }
        }

        const bills: Bill[] = data.map((d: Bill) => {
            return {
                ...d,
                nextDue: new Date(d.nextDue),
                periodAmount: AmountPerPeriodForBill(settings.billPeriodDuration, d.frequency, d.amount)
            }
        }).sort((a, b) => {
            return a.nextDue.getTime() > b.nextDue.getTime() ? 1 : -1
        })

        const billEvents = AnnualReserveForBills(bills, { excludePayments: false, billPeriod: settings.billPeriodStart, billPeriodDuration: settings.billPeriodDuration })

        return { bills: bills, billEvents: billEvents, requiredBalance: RequiredBalanceForBills(billEvents, settings.billPeriodStart) }
    }, [billQuery, appState.bills, appState.staged.bills, settings.billPeriodDuration, settings.billPeriodStart])

    return {
        data: {
            bills: bills,
            billEvents: billEvents,
            requiredBalance: requiredBalance,
        },
        stage: stage,
        save: save,
        revert: revert,
        isLoading:  billsMutation.isPending ? "Savings your Bill Information" : billQuery.isLoading ? "Fetching your Bills" : undefined,
    }
}

export const sortBills = (bills: Bill[], orderBy: string, order: string): Bill [] => {
    let sorted = [...bills]

    switch (orderBy) {
        case "name":
            sorted = bills.sort((a, b) => {
                const sort = a.name.localeCompare(b.name);
                return order === "asc" ? sort : -sort
            })
            break;
        case "frequency":
            sorted = bills.sort((a, b) => {
                const sort = daysPerPeriod(a.frequency) > (daysPerPeriod(b.frequency)) ? 1 : -1
                return order === "asc" ? sort : -sort
            })
            break;
        case "dueDate":
            sorted = bills.sort((a, b) => {
                const sort = a.nextDue.getTime() > b.nextDue.getTime() ? 1 : -1
                return order === "asc" ? sort : -sort
            })
            break;
        case "amount":
            sorted = bills.sort((a, b) => {
                const sort = a.amount > b.amount ? 1 : -1
                return order === "asc" ? sort : -sort
            })
            break;
        case "category":
            sorted = bills.sort((a, b) => {
                const sort = (a.category ?? "").localeCompare(b.category ?? "");
                return order === "asc" ? sort : -sort
            })
            break;
        case "periodAmount":
            sorted = bills.sort((a, b) => {
                const sort = (a.periodAmount ?? 0) > (b.periodAmount ?? 0) ? 1 : -1
                return order === "asc" ? sort : -sort
            })
            break;
        default:
            break;
    }

    return sorted    
}

export const filterBills = (bills: Bill[], filter: string | null): Bill[] => {
    let filtered = [...bills]
    if (filter != null) {
        filtered = bills.filter(b => b.name.toLowerCase().includes(filter.toLowerCase()))
    }
    return filtered
}