import { Table, TableHead, TableRow, TableCell, TableBody, Box, TextField, TableFooter, Typography } from "@mui/material"
import { NumericFormat } from "react-number-format"
import { moneyFormat, moneyFormatNeg } from "../components/MoneyFormat"
import { useState } from "react"
import { AttachMoney } from "@mui/icons-material"

export const SavingGoals = () => {
    const [goals, setGoals] = useState<Record<string, any>>({
        "Holiday Wages": {
            estimate: 833.38
        },
        "NDIS - Hailey": {
            estimate: 2304.91,
        },
        "School Excursions": {
            estimate: 440
        }
    })

    //ToDo: Get this from currentBalaces
    const balance = 15949.27
    const currentPeriodSavings = 3187

    return <>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}></TableCell>
                    <TableCell>Goal Estimate</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>Savings Account Balance</TableCell>
                    <TableCell>
                        <Box sx={{ display: { xs: 'table-cell', md: 'none' } }}>Savings Account Balance</Box>
                        <NumericFormat
                            value={balance}
                            onChange={e => {}}
                            onBlur={(e) => {}}
                            InputProps={moneyFormat}
                            customInput={TextField}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={","} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>Period Committment</TableCell>
                    <TableCell>
                        <Box sx={{ display: { xs: 'table-cell', md: 'none' } }}>Period Committment</Box>
                        <NumericFormat
                            value={currentPeriodSavings}
                            onChange={e => {}}
                            onBlur={(e) => {}}
                            InputProps={moneyFormat}
                            customInput={TextField}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={","} />
                    </TableCell>
                </TableRow>
                {
                    Object.keys(goals).map((g, i) => {
                        let goal = goals[g]
                        return <TableRow key={i}>
                            <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>{g}</TableCell>
                            <TableCell>
                                <Box sx={{ display: { xs: 'table-cell', md: 'none' } }}>{g}</Box>
                                <NumericFormat
                                    value={goal.estimate}
                                    onChange={e => {
                                        const i: string = e.target.value
                                        const newGoals = { ...goals }
                                        newGoals[g].estimate = parseFloat(i)
                                        setGoals(newGoals)
                                    }}
                                    // onBlur={(e) => {
                                    // ToDo: This would call the save function of the context state
                                    // }}
                                    InputProps={moneyFormatNeg}
                                    customInput={TextField}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={","} />
                            </TableCell>
                        </TableRow>
                    })
                }
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                        <Typography variant="h6">Total</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="h6">
                            <AttachMoney />
                            {Object.keys(goals).reduce((acc, g) => {
                                return acc - goals[g].estimate
                            }, balance + currentPeriodSavings).toFixed(2)}
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableFooter>
        </Table>
    </>
}