import React, { createContext, useState } from 'react';
import './App.css';
import { Bills } from './bills';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CssBaseline } from '@mui/material';
import { Route, Switch } from 'react-router-dom';
import { Budget } from "./budget";
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { ResponsiveAppBar } from './nav/AppBar';
import { formFields, components } from './auth/Authenticator';
import { Settings } from "./settings";
import { IAppContext, initialAppContext, initialAppState } from './service/context/appContext';
import { Savings } from './savings';
import { Notes } from './notes';
import { BillsImport } from './bills/BillsImport';

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: "us-east-1_ZQW6lTrnK",
            userPoolClientId: "2thn5p12nn3pbpo7bjl06clgbo",
            signUpVerificationMethod: "code",
            loginWith: {
                email: true
            },
            userAttributes: {
                given_name: { required: true }
            }
        }
    }
})

export const AppContext = createContext<IAppContext>(initialAppContext)

export const AuthorisedApp = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: Infinity,
            }
        },
    })

    return <Authenticator formFields={formFields} components={components}>
        <CssBaseline>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </CssBaseline>
    </Authenticator>
}

const App = () => {
    const [appState, setAppState] = useState(initialAppState);

    return <AppContext.Provider value={{ appState: appState, setAppState: setAppState }}>
        <ResponsiveAppBar />
        <Switch>
            <Route exact path={"/"} component={() => <Budget />} />
            <Route exact path={"/bills"} component={() => <Bills />} />
            <Route exact path={"/bills/import"} component={() => <BillsImport />} />
            <Route exact path={"/savings"} component={() => <Savings />} />
            <Route exact path={"/notes"} component={() => <Notes />} />
            <Route exact path={"/settings"} component={() => <Settings />} />
        </Switch>
    </AppContext.Provider>
}