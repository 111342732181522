export const useStore = () => {
    const set = (key:string, data : any) => localStorage.setItem(key, JSON.stringify(data));

    const get = (key: string) => {
        const r = localStorage.getItem(key)
        if (r != null) {
            return JSON.parse(r)
        }
        return null
    };

    const remove = (key: string) => localStorage.removeItem(key);

    return {set: set, get: get, remove: remove}
}