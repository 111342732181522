import { Note, newNote } from "./interfaces/Models"
import { Button, Container, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { useNotes } from "../service/hooks/useNotes"
import { useHistory } from "react-router-dom"

export const NoteTable = (props: { setActiveNote: (id: string) => void }) => {
    const history = useHistory()

    const { notes, setNotes } = useNotes()

    function createNote() {
        const n = newNote()
        if (notes == null || notes.length === 0) {
            setNotes([n])
        } else {
            setNotes([...notes, n])
        }

        const p = new URLSearchParams(history.location.search)
        p.set("note", n.id)

        history.push({
            search: p.toString(),
        })
    }

    return <Container>
        <h3>Quick Notes</h3>
        <Button onClick={createNote} fullWidth variant={"contained"}>Create New Note</Button>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width={"25%"}>Title</TableCell>
                    <TableCell width={"75%"}>Content</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {(notes == null || notes.length === 0) ?
                    <TableRow key="no-notes">
                        <TableCell colSpan={2} align={"center"}>
                            No notes logged
                        </TableCell>
                    </TableRow> :
                    notes.map((note: Note, index: number) => {
                        return <TableRow key={index} className={"cursor-pointer"} onClick={() => props.setActiveNote(note.id)}>
                            <TableCell>{note.title || "-"}</TableCell>
                            <TableCell>{note.content || "-"}</TableCell>
                        </TableRow>
                    })
                }
            </TableBody>
        </Table>
    </Container>
}