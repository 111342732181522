import { useMemo } from "react"
import { useHistory } from "react-router-dom"
import { NoteTable } from "./NoteTable"
import { NoteDetail } from "./NoteDetail"


export const Notes = () => {
    const history = useHistory()

    const { activeNote } = useMemo(() => {
        const params = new URLSearchParams(history.location.search)
        return {
            activeNote: params.get("note"),
        }
    }, [history.location.search])

    function setActiveNote(noteID?: string) {
        const p = new URLSearchParams(history.location.search)

        if (noteID == null || noteID === "") {
            p.delete("note")
        } else {
            p.set("note", noteID)
        }

        history.push({
            search: p.toString(),
        })
    }

    return <>
        {activeNote == null ?
            <NoteTable setActiveNote={setActiveNote} /> :
            <NoteDetail />}
    </>
}