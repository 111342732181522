import { useQuery, useMutation } from "@tanstack/react-query"
import { useCallback, useContext, useMemo } from "react"
import { getBalances, saveBalances } from "../api/balances"
import { Balance, Balances } from "../../budget/interfaces/Models"
import { AppContext } from "../../App";

export interface useCurrentBalancesResponse {
    currentBalances: Record<Balance, number>;
    stage: (balance: Record<Balance, number>) => void;
    save: () => void;
    revert: ()=> void;
    isLoading: string | undefined;
}

export const useCurrentBalances = (): useCurrentBalancesResponse => {
    const { appState, setAppState } = useContext(AppContext)

    const balancesQuery = useQuery({
        queryKey: ["get-balances"],
        queryFn: () => getBalances(),
        staleTime: Infinity,
    })

    const balancesMutation = useMutation({
        mutationKey: ["save-balances"],
        mutationFn: (cmd: Balances) => saveBalances(cmd),
        onSuccess: () => {
            balancesQuery.refetch().then(res => {
                if (res.data != null) {
                    setAppState(prev => { return { ...prev, currentBalances: res.data, staged: {...prev.staged, currentBalances: false} } })
                } else {
                    setAppState(prev => { return { ...prev, staged: {...prev.staged, currentBalances: false} } })
                }
            })
        }
    })

    const stage = useCallback((balance: Record<Balance, number>) => {
        setAppState(prev => {
            return {
                ...prev,
                staged: {...prev.staged, currentBalances: true},
                currentBalances: balance
            }
        })
    }, [setAppState])

    const save = useCallback(() => {
        if (appState.currentBalances != null) {
            balancesMutation.mutate(appState.currentBalances)
        }
    }, [appState.currentBalances, balancesMutation])

    const revert = useCallback(() => {
        setAppState(prev => {
            return {
                ...prev,
                staged: {...prev.staged, currentBalances: false},
                currentBalances: balancesQuery.data
            }
        })
    },[balancesQuery.data, setAppState])

    const currentBalances = useMemo(() => {
        if (appState.staged.currentBalances && appState.currentBalances != null) {
            return appState.currentBalances
        } else {
            return balancesQuery.data ?? { "bills": 0, "expense": 0, "savings": 0 }
        }
    }, [balancesQuery.data, appState.staged.currentBalances, appState.currentBalances])

    return {
        currentBalances: currentBalances,
        stage,
        save,
        revert,
        isLoading: balancesMutation.isPending ? "Savings your Balance Information" : balancesQuery.isLoading ? "Fetching your Balances" : undefined
    }
}