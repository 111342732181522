import { Alert, Container, FormControl, Table, TableRow, TableHead, TextField, TableCell, TableBody, Button } from "@mui/material"
import { useMemo, useState } from "react"
import { Bill } from "./interfaces/Models"
import { EventFrequency } from "../components/interfaces/EventFrequency"
import { useBills } from "../service/hooks/useBills"

export const BillsImport = () => {
    const [pasted, setPasted] = useState<string>("")

    const {stage} = useBills()

    const parsedBills : {bills: Bill[], error?: string} = useMemo(() => {
        if (pasted === "") {
            return {bills: []}
        }

        let bills : Bill[] = []
        try {
            bills = pasted.split("\n").filter(f => {
                return f.length !== 0
            }).map((l, i) => {
                const parts = l.split("\t")

                // Cater for non-standard Weekly values
                let frequency : EventFrequency
                if (["Year", "Half-Year", "Quarter", "Month", "Fortnight", "Week"].includes(parts[1])) {
                    frequency = parts[1] as EventFrequency
                } else if (parts[1] === "Weekly") {
                    frequency = "Week"
                } else {
                    throw new Error("Invalid frequency")
                }

                // Manually parse the date values
                const [day, month, year] = parts[3].split("/");

                // Generate a unique id using time and index
                const id = `${new Date().getTime()}-${i}`
                
                return {
                    id: id,
                    name: parts[0],
                    frequency: frequency,
                    amount: parseFloat(parts[2].replace("$", "")),
                    nextDue: new Date(`${year}-${month}-${day}`),
                    category: parts[4],
                }
            })
        } catch {
            return {bills: [], error: "Unable to parse pasted data"}
        }

        return {bills: bills}
    },[pasted])

    return <Container>
        <h3>Import Bills</h3>
        <p>Accepted format is Copy/Paste from Microsoft Excel (tab separated and new-line terminations)</p>
        <p>HINT: The column order of the pasted data should match that of the table below.</p>
        <FormControl fullWidth={true}>
            <TextField 
                key={"paste"}
                label={"Paste Bills"}
                placeholder={"Paste Bill Data"}
                autoComplete={"none"}
                multiline
                rows={10}
                value={pasted}
                onChange={(e) => {
                    const v = e.target.value
                    setPasted(v)}
                }/>
        </FormControl>
        <h4>Parsed Bill Records will Display here... Scroll to the bottom to accept them.</h4>
        <p>Once accepted, they will be staged and ready to save.</p>
        {parsedBills.error != null ? 
            <Alert severity={"error"}>{parsedBills.error}</Alert> :
            <>
                <Table stickyHeader={true}>
                    <TableHead >
                        <TableRow>
                            <TableCell>Bill Name</TableCell>
                            <TableCell>Frequency</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Next Due</TableCell>
                            <TableCell>Category</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {parsedBills.bills.map((b, i) => 
                            <TableRow key={i}>
                                <TableCell>{b.name}</TableCell>
                                <TableCell>{b.frequency}</TableCell>
                                <TableCell>{b.amount}</TableCell>
                                <TableCell>{b.nextDue.toLocaleDateString()}</TableCell>
                                <TableCell>{b.category}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <br/>
                <Button 
                    disabled={parsedBills.bills.length === 0}
                    variant="contained"
                    onClick={() => stage(parsedBills.bills)}>
                        Accept
                </Button>
            </> }
    </Container>
}