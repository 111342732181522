import FormControl from "@mui/material/FormControl";
import {Grid, InputLabel} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useSettings} from "../service/hooks/useSettings";

export const PeriodLength = () => {
    const {settings, setSettings} = useSettings()

    return <Grid container>
        <FormControl fullWidth={true}>
            <InputLabel id="frequency-select-label">Standard Period Duration</InputLabel>
            <Select fullWidth={true}
                    labelId="frequency-select-label"
                    id="frequency-select"
                    label={"Standard Period Duration"}
                    onChange={(i) => {
                        let v = i.target.value
                        setSettings({billPeriodDuration: v as "Month" | "Fortnight" | "Week"})
                    }}
                    value={settings.billPeriodDuration}>
                <MenuItem value={"Week"} contextMenu="">Week</MenuItem>
                <MenuItem value={"Fortnight"}>Fortnight</MenuItem>
                <MenuItem value={"Month"}>Month</MenuItem>
            </Select>
        </FormControl>
    </Grid>
}