import { Container } from "@mui/material";
import { AccountBalances } from "./AccountBalances";
import {BudgetPeriods} from "./BudgetPeriods";
import { IncomeTable } from "../income/IncomeTable";

export const Budget = () => {
    return <Container>
        <BudgetPeriods/>
        <AccountBalances />
        <IncomeTable />
    </Container>
};
