import { StandardEvent } from "../../components/interfaces/StandardEvent"

export interface Bill extends StandardEvent {
    category?: string
    periodAmount?: number | null
    balanceAfter?: number
}

export const NewBillID = "new"

export const PaymentLogID = "payment"
