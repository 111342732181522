import { NewBillID } from "./interfaces/Models";
import { AUDollar } from "../service/functions/moneyFormat";
import { WithHook } from "../service/hooks/withHook";
import { Box, Button, Container, Grid, InputAdornment, Tab, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, TextField, Typography } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useMemo, useState } from "react";
import Search from "@mui/icons-material/Search";
import { useSettings } from "../service/hooks/useSettings";
import { useHistory } from "react-router-dom";
import { compareToNow } from "../service/functions/compareToNow";
import { useBills, filterBills, sortBills } from "../service/hooks/useBills";
import { rowColors } from "../service/functions/rowColors";

export type BillTableView = "schedule" | "bills" | null

export const BillTable = ({ setActive, view, setView }: { 
    setActive: (id: string) => void, 
    view: BillTableView, 
    setView: (view: BillTableView) => void 
}) => {
    const {settings} = useSettings()
    const {data, isLoading} = useBills()
    const history = useHistory()

    const [orderBy, setOrderBy] = useState("")
    const [order, setOrder] = useState<"asc" | "desc">("asc")
    const [filter, setFilter] = useState<string | null>(null)

    const {sortedBills, sortedBillEvents} = useMemo(() => {
        const filteredBills = filterBills(data.bills, filter)
        const sortedBills = sortBills(filteredBills, orderBy, order)
        const sortedBillEvents = sortBills(data.billEvents, orderBy, order)
        return {sortedBills, sortedBillEvents}
    }, [data.bills, data.billEvents, filter, orderBy, order])

    const { detail } = useMemo(() => {
        const params = new URLSearchParams(history.location.search)
        return {
            detail: params.get("detail") as "more" | "all" | null,
        }
    }, [history.location.search])

    const headers = useMemo(() => {
        function onSortHeader(name: string) {
            if (orderBy === name) {
                setOrder(prev => {
                    return prev === "asc" ? "desc" : "asc"
                })
            } else {
                setOrder("asc")
            }
            setOrderBy(name)
        }

        let h = [
            <TableCell key={"name"}>
                <TableSortLabel
                    active={orderBy === "name"}
                    direction={orderBy === "name" ? order : "asc"}
                    disabled={view !== "bills"}
                    onClick={() => onSortHeader("name")}>
                    Bill Name
                </TableSortLabel>
            </TableCell>,
            <TableCell key={"frequency"} sx={{ display: { xs: 'none', md: 'table-cell' }}}>
                <TableSortLabel
                    active={orderBy === "frequency"}
                    direction={orderBy === "frequency" ? order : "asc"}
                    disabled={view !== "bills"}
                    onClick={() => onSortHeader("frequency")}>
                    Frequency
                </TableSortLabel>
            </TableCell>,
            <TableCell key={"due_date"} sx={{ display: { xs: 'none', md: 'table-cell' }}}>
                <TableSortLabel
                    active={orderBy === "dueDate"}
                    direction={orderBy === "dueDate" ? order : "asc"}
                    disabled={view !== "bills"}
                    onClick={() => onSortHeader("dueDate")}>
                    Due Date
                </TableSortLabel>
            </TableCell>,
            <TableCell key={"amount"}>
                <TableSortLabel
                    active={orderBy === "amount"}
                    direction={orderBy === "amount" ? order : "asc"}
                    disabled={view !== "bills"}
                    onClick={() => onSortHeader("amount")}
                >
                    Amount
                </TableSortLabel>
            </TableCell>,
            <TableCell key={"category"} sx={{ display: { xs: 'none', md: 'table-cell' }}}>
                <TableSortLabel
                    active={orderBy === "category"}
                    direction={orderBy === "category" ? order : "asc"}
                    disabled={view !== "bills"}
                    onClick={() => onSortHeader("category")}>
                    Category
                </TableSortLabel>
            </TableCell>
        ]

        if (view === "schedule") {
            h.push(<TableCell key={"after"} sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                Balance After
            </TableCell>)
        } else {
            h.push(<TableCell key={"period"} sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                <TableSortLabel
                    active={orderBy === "periodAmount"}
                    direction={orderBy === "periodAmount" ? order : "asc"}
                    onClick={() => onSortHeader("periodAmount")}
                >
                    Per {settings.billPeriodDuration}
                </TableSortLabel>
            </TableCell>)
        }

        return <TableHead sx={{ position: "sticky", top: 0 }}>
            <TableRow key={"header_row"}>
                {h}
            </TableRow>
            <TableRow key={"filter_row"}>
                <TableCell colSpan={6}>
                    <TextField
                        autoComplete={"none"}
                        value={filter || ""}
                        fullWidth
                        onChange={e => setFilter(e.target.value)}
                        placeholder={"Filter by Bill Name..."}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>)
                        }}
                    />
                </TableCell>
            </TableRow>
        </TableHead>
    }, [view, order, orderBy, filter, setOrder, setOrderBy, setFilter, settings.billPeriodDuration])

    const scheduleTable = useMemo(() => {
        let events = sortedBillEvents

        switch (detail) {
            case null:
                const paymentIndex = events.findIndex(e => e.name === 'Payment')
                events = paymentIndex !== -1 ? events.slice(0, paymentIndex + 1) : events
                break;
            case "more":
                let count = 0
                const secondPaymentIndex = events.findIndex(e => {
                    if (e.name === 'Payment') count++;
                    return count === 2;
                })
                events = secondPaymentIndex !== -1 ? events.slice(0, secondPaymentIndex + 1) : events
                break;
            default:
                break;
        }

        const b = events.map(e => {
            const { isToday, isOverdue } = compareToNow(e.nextDue)

            return <TableRow style={rowColors(isOverdue, isToday, e.id === "payment")}
                key={`${e.id}_${e.nextDue.getTime()}`}
                onClick={() => {
                    if (e.id !== "payment") {
                        setActive(e.id)
                    }
                }}
                className={e.id !== "payment" ? "cursor-pointer" : ""}
            >
                <TableCell key={"name"}>
                    <Box>{e.name}</Box>
                    <Box sx={{display: { xs: 'flex', md: 'none' }, color: "gray"}}>
                        {e.nextDue.toLocaleDateString('en-AU', { day: 'numeric', month: 'short', year: 'numeric' })}
                    </Box>
                </TableCell>
                <TableCell key={"frequency"} sx={{ display: { xs: 'none', md: 'table-cell' } }}>{e.frequency}</TableCell>
                <TableCell key={"due_date"} sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                    {e.nextDue.toLocaleDateString('en-AU', { day: 'numeric', month: 'short', year: 'numeric' })}
                </TableCell>
                <TableCell key={"amount"}>
                    <Box>{AUDollar.format(e.amount)}</Box>
                    <Box sx={{display: { xs: 'flex', md: 'none' }, color: "gray"}}>per {e.frequency}</Box>
                </TableCell>
                <TableCell key={"category"} sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                    <Box>{e.category ?? "-"}</Box>
                </TableCell>
                <TableCell key={"after"} sx={{ display: { xs: 'none', md: 'table-cell' } }}>{AUDollar.format(e.balanceAfter || 0)}</TableCell>
            </TableRow>
        })
        return <TableBody>{b}</TableBody>
    }, [sortedBillEvents, detail, setActive])

    const billsTable = useMemo(() => {
        const b = sortedBills.map(e => {
            const { isToday, isOverdue } = compareToNow(e.nextDue)
            return <TableRow style={rowColors(isOverdue, isToday, false)}
                key={e.id}
                onClick={() => {
                    if (e.id !== "payment") {
                        setActive(e.id)
                    }
                }}
                className={"cursor-pointer"}
            >
                <TableCell>   
                    <Box>{e.name}</Box>
                    <Box sx={{display: { xs: 'flex', md: 'none' }, color: "gray"}}>
                        {e.nextDue.toLocaleDateString('en-AU', { day: 'numeric', month: 'short', year: 'numeric' })}
                    </Box>
                </TableCell>
                <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>{e.frequency}</TableCell>
                <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                    {e.nextDue.toLocaleDateString('en-AU', { day: 'numeric', month: 'short', year: 'numeric' })}
                </TableCell>
                <TableCell>
                    <Box>{AUDollar.format(e.amount)}</Box>
                    <Box sx={{display: { xs: 'flex', md: 'none' }, color: "gray"}}>per {e.frequency}</Box>
                </TableCell>
                <TableCell key={"category"} sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                    <Box>{e.category ?? "-"}</Box>
                </TableCell>
                <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>{AUDollar.format(e.periodAmount || 0)}</TableCell>
            </TableRow >
        })
        return <TableBody>{b}</TableBody>
    }, [sortedBills, setActive])

    function onSetView(v: BillTableView) {
        if (v === "bills") {
            onSetDetail("bills")
        }
        setView(v)
    }

    function onSetDetail(d?: "bills") {
        const p = new URLSearchParams(history.location.search)
        if (d === "bills") {
            p.delete("detail")
        } else if (detail === null) {
            p.set("detail", "more")
        } else if (detail === "more") {
            p.set("detail", "all")
        } else {
            p.delete("detail")
        }

        history.push({
            search: p.toString(),
        })
    }

    return <WithHook isLoading={isLoading}>
        <Container maxWidth={"md"}>
            <Grid>
                <TabContext value={view ?? "bills"}>
                    <TabList onChange={(_, v) => onSetView(v)} aria-label="basic tabs example" centered>
                        <Tab label="Bills" value={"bills"} />
                        <Tab label="Schedule" value={"schedule"} />
                    </TabList>
                    <TabPanel value="schedule">
                        <div>Based on your Bill Entries, the required Balance as at {new Date().toDateString()} is <b>{AUDollar.format(data.requiredBalance)}</b></div>
                        <Table stickyHeader>
                            {headers}
                            {scheduleTable}
                        </Table>
                        <Box textAlign="center">
                            <Button fullWidth onClick={() => onSetDetail()}>
                                <Typography variant="body1">
                                    {detail === "all" ? "Show Less..." : "Show More..."}
                                </Typography>
                            </Button>
                        </Box>
                    </TabPanel>
                    <TabPanel value="bills">
                        <Button fullWidth
                            onClick={() => setActive(NewBillID)}
                            color={"info"}
                            variant={"contained"}>
                            Create New Bill Entry
                        </Button>
                        <Table stickyHeader>
                            {headers}
                            {billsTable}
                        </Table>
                    </TabPanel>
                </TabContext>
            </Grid>
        </Container>
    </WithHook>
}
