import { Table, TableHead, TableRow, TableCell, TableBody, TextField, Box } from "@mui/material"
import { useCurrentBalances } from "../service/hooks/useCurrentBalances"
import {AUDollar} from "../service/functions/moneyFormat";
import { useBills } from "../service/hooks/useBills";
import { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { moneyFormat } from "../components/MoneyFormat";

export const AccountBalances = () => {
    const {currentBalances, stage} = useCurrentBalances()
    const [balances, setBalances] = useState(currentBalances)
    useEffect(() => {
        setBalances(currentBalances)
    }, [currentBalances])

    const {data} = useBills()

    return <>
    <h3>Account Balances</h3>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell sx={{display: {xs : 'none', md: 'table-cell'}}}></TableCell>
                    <TableCell>Actual</TableCell>
                    <TableCell>Required</TableCell>
                    <TableCell sx={{display: {xs: 'none'}}}>Variation</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell sx={{display: {xs: 'none', md: 'table-cell'}}}>General Expense Account</TableCell>
                    <TableCell>
                        <Box sx={{display: {xs: 'table-cell', md:'none'}}}>General Expence Account</Box>
                        <NumericFormat 
                            value={balances.expense}
                            onChange={e => {
                                const i : string = e.target.value
                                setBalances({...balances, expense: parseFloat(i)})
                            }}
                            onBlur={(e) => {
                                const i : string = e.target.value
                                stage({...balances, expense: parseFloat(i)})
                            }}
                            InputProps={moneyFormat}
                            customInput={TextField}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={","} />
                    </TableCell>
                    <TableCell>
                        {AUDollar.format(balances.expense)}
                    </TableCell>
                    <TableCell sx={{display: {xs: 'none'}}}>
                        {AUDollar.format(0)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{display: {xs: 'none', md: 'table-cell'}}}>Fixed Expense Account</TableCell>
                    <TableCell>
                        <Box sx={{display: {xs: 'table-cell', md:'none'}}}>Fixed Expence Account</Box>
                        <NumericFormat
                            value={balances.bills}
                            onChange={e => {
                                const i = e.target.value
                                setBalances({...balances, bills: parseFloat(i)})
                            }}
                            onBlur={(e) => {
                                const i : string = e.target.value
                                stage({...balances, bills: parseFloat(i)})
                            }}
                            InputProps={moneyFormat}
                            customInput={TextField}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={","} />
                    </TableCell>
                    <TableCell>
                        {AUDollar.format(data.requiredBalance)}
                    </TableCell>
                    <TableCell sx={{display: {xs: 'none'}}}>
                        {AUDollar.format(balances.bills - data.requiredBalance)}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </>
}