import { fetchAuthSession } from "aws-amplify/auth";
import { Bill } from "../../bills/interfaces/Models";
import { api } from "./api";

export async function getBills() : Promise<Bill[]> {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {}
    return api(accessToken?.toString()).get("/bills").then(res => res.data)
}

export async function saveBills(cmd : Bill[]) {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {}
    return api(accessToken?.toString()).put("/bills", cmd).then(res => res)
}