import {fetchAuthSession} from "aws-amplify/auth";
import {api} from "./api";
import { Balances } from "../../budget/interfaces/Models";

export async function getBalances() : Promise<Balances> {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {}
    return api(accessToken?.toString()).get("/balances").then(res => res.data)
}

export async function saveBalances(cmd : Balances) {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {}
    return api(accessToken?.toString()).put("/balances", cmd).then(res => res)
}