import { Add } from "@mui/icons-material"
import { MenuItem, Select as MUISelect, SelectChangeEvent, SelectProps, TextField } from "@mui/material"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from "react";

export const SelectWithAdd = (props: SelectProps<string> & { selectitems: string[], setnewselectitem: (i: string) => void, addlabel: string }) => {
    const [open, setOpen] = useState(false);
    const [category, setCategory] = useState("")

    function onChange(event: SelectChangeEvent<string>, child: React.ReactNode) {
        event.preventDefault()
        if (props.onChange && event.target.value !== "~add~item~") {
            props.onChange(event, child)
        }
        setCategory("")
    }

    return <>
        <Dialog open={open} onClose={() => setOpen(false)} keepMounted={false}>
            <DialogTitle>Add New Category</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <TextField
                        onChange={(e) => {
                            const v = e.currentTarget.value
                            setCategory(v)
                        }}
                        value={category} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    props.setnewselectitem(category)
                    setOpen(false)
                }}>
                    Create
                </Button>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
        <MUISelect {...props} onChange={onChange}>
            {props.selectitems.map(c => <MenuItem value={c} key={c}>{c}</MenuItem>)}
            <MenuItem value={"~add~item~"} key={"~add~item~"} onClick={() => setOpen(true)}>
                <>
                    <Add />
                    {props.addlabel}
                </>
            </MenuItem>
        </MUISelect>
    </>
}