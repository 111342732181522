import { Table, TableHead, TableRow, TableCell, TableBody, Box, TableSortLabel, TextField, InputAdornment } from "@mui/material"
import { useMemo, useState } from "react"
import { useSettings } from "../service/hooks/useSettings"
import { AUDollar } from "../service/functions/moneyFormat"
import { rowColors } from "../service/functions/rowColors"
import { compareToNow } from "../service/functions/compareToNow"
import { Search } from "@mui/icons-material"
import { filterIncome, sortIncome, useIncome } from "../service/functions/useIncome"

export type IncomeTableView = "schedule" | "income" | null

export const IncomeTable = () => {
    const {incomeStreams} = useIncome()

    const [view] = useState<IncomeTableView>("income")

    const [orderBy, setOrderBy] = useState("")
    const [order, setOrder] = useState<"asc" | "desc">("asc")
    const [filter, setFilter] = useState<string | null>(null)

    const { settings } = useSettings()

    // const periodIncome = incomeStreams.filter(f => {
    //     const fDate = new Date(f.nextDue.getFullYear(), f.nextDue.getMonth(), f.nextDue.getDate());
    //     const eventDate = new Date(settings.billPeriodEnd.getFullYear(), settings.billPeriodEnd.getMonth(), settings.billPeriodEnd.getDate());
    //     return fDate.getTime() < eventDate.getTime();
    // }).sort((a, b) => a.nextDue.getTime() < b.nextDue.getTime() ? -1 : 1)

    const {sortedIncome} = useMemo(() => {
        const filteredIncome = filterIncome(incomeStreams, filter)
        const sortedIncome = sortIncome(filteredIncome, orderBy, order)
        return {sortedIncome}
    }, [incomeStreams, filter, orderBy, order])

    const headers = useMemo(() => {
        function onSortHeader(name: string) {
            if (orderBy === name) {
                setOrder(prev => {
                    return prev === "asc" ? "desc" : "asc"
                })
            } else {
                setOrder("asc")
            }
            setOrderBy(name)
        }

        let h = [
            <TableCell key={"source"}>
                <TableSortLabel
                    active={orderBy === "name"}
                    direction={orderBy === "billName" ? order : "asc"}
                    disabled={view !== "income"}
                    onClick={() => onSortHeader("name")}>
                    Income Source
                </TableSortLabel>
            </TableCell>,
            <TableCell key={"frequency"} sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                <TableSortLabel
                    active={orderBy === "frequency"}
                    direction={orderBy === "frequency" ? order : "asc"}
                    disabled={view !== "income"}
                    onClick={() => onSortHeader("frequency")}>
                    Frequency
                </TableSortLabel>
            </TableCell>,
            <TableCell key={"due_date"} sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                <TableSortLabel
                    active={orderBy === "dueDate"}
                    direction={orderBy === "dueDate" ? order : "asc"}
                    disabled={view !== "income"}
                    onClick={() => onSortHeader("dueDate")}>
                    Next Due
                </TableSortLabel>
            </TableCell>,
            <TableCell key={"amount"}>
                <TableSortLabel
                    active={orderBy === "amount"}
                    direction={orderBy === "amount" ? order : "asc"}
                    disabled={view !== "income"}
                    onClick={() => onSortHeader("amount")}
                >
                    Amount
                </TableSortLabel>
            </TableCell>
        ]

        return <TableHead sx={{ position: "sticky", top: 0 }}>
            <TableRow key={"header_row"}>
                {h}
            </TableRow>
            <TableRow key={"filter_row"}>
                <TableCell colSpan={4}>
                    <TextField
                        autoComplete={"none"}
                        value={filter || ""}
                        fullWidth
                        onChange={e => setFilter(e.target.value)}
                        placeholder={"Filter by Bill Name..."}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>)
                        }}
                    />
                </TableCell>
            </TableRow>
        </TableHead>
    }, [view, order, orderBy, filter, setOrder, setOrderBy, setFilter])

    return <>
        <h3>Income</h3>
        <Table>
            {headers}
            <TableBody>
                {sortedIncome.map((e, idx) => {
                    const { isToday, isOverdue } = compareToNow(e.nextDue)

                    return <TableRow style={rowColors(isOverdue, isToday, false)}
                        key={idx}
                        // ToDo: Implement Me...
                        onClick={() => { }}
                        className={"cursor-pointer"}
                    >
                        <TableCell key={"name"}>
                            <Box>{e.name}</Box>
                            <Box sx={{ display: { xs: 'flex', md: 'none' }, color: "gray" }}>
                                {e.nextDue.toLocaleDateString('en-AU', { day: 'numeric', month: 'short', year: 'numeric' })}
                            </Box>
                        </TableCell>
                        <TableCell key={"frequency"} sx={{ display: { xs: 'none', md: 'table-cell' } }}>{e.frequency}</TableCell>
                        <TableCell key={"due_date"} sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                            {e.nextDue.toLocaleDateString('en-AU', { day: 'numeric', month: 'short', year: 'numeric' })}
                        </TableCell>
                        <TableCell key={"amount"}>
                            <Box>{AUDollar.format(e.amount)}</Box>
                            <Box sx={{ display: { xs: 'flex', md: 'none' }, color: "gray" }}>per {e.frequency}</Box>
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
}