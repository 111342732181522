import { EventFrequency } from "../../components/interfaces/EventFrequency";

//DaysPerPeriod converts a period to the number of days calculated for that period
export function daysPerPeriod(period: EventFrequency): number {
    switch (period) {
        case "Year":
            return 365;
        case "Half-Year":
            return 365 / 2;
        case "Quarter":
            return 365 / 4;
        case "Month":
            return 365 / 12;
        case "Fortnight":
            return 14;
        case "Week":
            return 7;
    }
}
