import { Bill } from "../interfaces/Models";
import { EventFrequency } from "../../components/interfaces/EventFrequency";

export function AmountPerPeriodForBill(billPeriod: "Month" | "Fortnight" | "Week", frequency?: EventFrequency, amount?: number): number | null {
    let periods: number | null = null;

    switch (billPeriod) {
        case "Month":
            switch (frequency) {
                case "Year":
                    periods = 12;
                    break;
                case "Half-Year":
                    periods = 6;
                    break;
                case "Quarter":
                    periods = 3;
                    break;
                case "Month":
                    periods = 1;
                    break;
                case "Fortnight":
                    periods = 26 / 12;
                    break;
                case "Week":
                    periods = 52 / 12;
                    break;
                default:
                    periods = null;
                    break;
            }
            break;
        case "Fortnight":
            switch (frequency) {
                case "Year":
                    periods = 26;
                    break;
                case "Half-Year":
                    periods = 13;
                    break;
                case "Quarter":
                    periods = 26 / 4;
                    break;
                case "Month":
                    periods = 26 / 12;
                    break;
                case "Fortnight":
                    periods = 1;
                    break;
                case "Week":
                    periods = 0.5;
                    break;
                default:
                    periods = null;
                    break;
            }
            break;
        case "Week":
            switch (frequency) {
                case "Year":
                    periods = 52;
                    break;
                case "Half-Year":
                    periods = 26;
                    break;
                case "Quarter":
                    periods = 52 / 4;
                    break;
                case "Month":
                    periods = 52 / 12;
                    break;
                case "Fortnight":
                    periods = 2;
                    break;
                case "Week":
                    periods = 1;
                    break;
                default:
                    periods = null;
                    break;
            }
            break;
    }

    if (frequency == null || amount == null || periods == null) {
        return null;
    }

    return +((amount / periods).toFixed(2));
}

export function AmountPerPeriodForBills(bills: Bill[], billPeriod: "Month" | "Fortnight" | "Week"): number {
    let response: number = 0;

    bills.forEach(b => {
        response += (AmountPerPeriodForBill(billPeriod, b.frequency, b.amount) ?? 0);
    });

    return response;
}
