import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {Settings} from "../../settings/interfaces/Models";
import {getSettings, saveSettings} from "../api/settings";
import { offsetDateByFrequency } from "../functions/offsetDateByFrequency";

export interface useSettingsResponse {
    settings: Settings;
    setSettings: (props: {billPeriodStart?: Date, billPeriodDuration?: "Month" | "Fortnight" | "Week"}) => void;
}

export const useSettings = () => {
    const queryClient = useQueryClient()
    
    const settingsQuery = useQuery({
        queryKey: ["get-settings"],
        queryFn: () => {
            return getSettings()
        },
        staleTime: Infinity,
    })

    const settingsMutation = useMutation({
        mutationKey: ["save-settings"],
        mutationFn: (props: {cmd: Settings, onSuccess: ()=>void}) => {
            return saveSettings(props.cmd)
        },
        onSuccess: (_, props) => {
            settingsQuery.refetch()
            queryClient.invalidateQueries({queryKey: ["get-bills"]})
            props.onSuccess()
        }
    })

    const billPeriodStart = settingsQuery.data?.billPeriod ?? (new Date())
    const billPeriodDuration = settingsQuery.data?.billPeriodDuration ?? "Week"
    const settings = {
        billPeriodStart: billPeriodStart,
        billPeriodEnd: offsetDateByFrequency(billPeriodStart, billPeriodDuration, 1),
        billPeriodDuration: billPeriodDuration
    }

    const setSettings = (props : {billPeriodStart?: Date, billPeriodDuration?: "Month" | "Fortnight" | "Week"}) => {
        let cmd = {
            billPeriod: props.billPeriodStart ?? settings.billPeriodStart,
            billPeriodDuration: props.billPeriodDuration ?? settings.billPeriodDuration
        }

        settingsMutation.mutate({cmd: cmd, onSuccess: ()=> null})
    }

    return {settings, setSettings}
}