import { Dispatch, SetStateAction } from "react"
import { Balance } from "../../budget/interfaces/Models"
import { Bill } from "../../bills/interfaces/Models"

export interface IAppState {
    currentBalances?: Record<Balance, number>
    bills?: Bill[]
    isStaged: boolean
    staged: {
        currentBalances: boolean
        bills: boolean
    
    }
}

export const initialAppState : IAppState = {
    currentBalances: {"bills": 0, "expense": 0, "savings": 0},
    bills: [],
    isStaged: false,
    staged: {
        currentBalances: false,
        bills: false
    }
}

export interface IAppContext {
    appState: IAppState,
    setAppState: Dispatch<SetStateAction<IAppState>>
}

export const initialAppContext : IAppContext= {
    appState: initialAppState, 
    setAppState: (_: SetStateAction<IAppState>) => {}
}