import { BillDetail } from "./BillDetail"
import { useHistory } from "react-router-dom"
import { BillTable, BillTableView } from "./BillTable"
import { useMemo } from "react"

export const Bills = () => {
    const history = useHistory()

    const { activeBill, view } = useMemo(() => {
        const params = new URLSearchParams(history.location.search)
        return {
            activeBill: params.get("bills"),
            view: params.get("view") as BillTableView
        }
    }, [history.location.search])

    function setView(billID?: string, tableMode?: BillTableView) {
        const p = new URLSearchParams(history.location.search)

        if (billID == null || billID === "") {
            p.delete("bills")
        } else {
            p.set("bills", billID)
        }

        if (tableMode != null) {
            p.set("view", tableMode)
        }

        history.push({
            search: p.toString(),
        })
    }

    return <>
        {activeBill == null ?
            <BillTable setActive={(i) => setView(i)} view={view} setView={(v) => setView("", v)} /> :
            <BillDetail onGoBack={() => setView()} />}
    </>
}

