import { Container, Grid, FormControl, TextField, Button } from "@mui/material"
import { useState } from "react"
import { Note, newNote } from "./interfaces/Models"
import { useHistory } from "react-router-dom"
import { useNotes } from "../service/hooks/useNotes"

export const NoteDetail = () => {
    const history = useHistory()

    const {notes, setNotes} = useNotes()

    const params = new URLSearchParams(history.location.search)

    const [note, setNote] = useState<Note>(newNote(notes.find(n => n.id === params.get("note"))))

    function onCancel() {
        setNote(newNote(notes.find(n => n.id === params.get("note"))))

        const p = new URLSearchParams(history.location.search)
        p.delete("note")

        history.push({
            search: p.toString(),
        })
    }

    function onSave() {
        setNotes(notes.map(n => n.id === note.id ? note : n))

        const p = new URLSearchParams(history.location.search)
        p.delete("note")

        history.push({
            search: p.toString(),
        })
    }

    function onDelete() {
        setNotes(notes.filter(n => n.id !== note.id))

        const p = new URLSearchParams(history.location.search)
        p.delete("note")

        history.push({
            search: p.toString(),
        })
    }
    
    return <Container>
        <h3>Notes</h3>
        {note != null && 
        <Grid>
            <FormControl fullWidth={true}>
                <TextField 
                    key={"title"}
                    label={"Note Title"}
                    placeholder={"Note Title"}
                    autoComplete={"none"}
                    value={note.title}
                    onChange={(e) => {
                        const v = e.target.value
                        setNote(prev => {
                            return {...prev, title: v}
                        })}
                    }/>
            </FormControl>
            <FormControl fullWidth={true}>
                <TextField 
                    multiline
                    rows={4}
                    key={"content"}
                    label={"Content"}
                    placeholder={"Content"}
                    autoComplete={"none"}
                    value={note.content}
                    onChange={(e) => {
                        const v = e.target.value
                        setNote(prev => {
                            return {...prev, content: v}
                        })}
                    }/>
            </FormControl>
            <Button onClick={onSave}>Done</Button>
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={onDelete}>Delete</Button>
        </Grid>}
    </Container>
}