import { AttachMoney, Remove } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";

export const moneyFormat = {
    startAdornment: (
        <InputAdornment position="start">
            <AttachMoney />
        </InputAdornment>)
};

export const moneyFormatNeg = {
    startAdornment: (
        <InputAdornment position="start">
            <Remove sx={{color:"red"}} />
            <AttachMoney />
        </InputAdornment>)
}