import { daysPerPeriod } from "../../service/functions/daysPerPeriod"
import { PaymentLogID } from "../interfaces/Models"
import { Bill } from "../interfaces/Models"
import { AmountPerPeriodForBills } from "./AmountPerPeriodForBills"
import { offsetDateByFrequency } from "../../service/functions/offsetDateByFrequency"

function annualReserveForBill(bill: Bill): Bill[] {
    let response: Bill[] = []

    for (let period = 0; period < 365 / daysPerPeriod(bill.frequency); period++) {
        response.push({ 
            ...bill,
            nextDue: offsetDateByFrequency(bill.nextDue, bill.frequency, period)
        })
    }

    return response
}

//AnnualReserveForBills returns the schedule of events according to a supplied array of bills
export function AnnualReserveForBills(bills: Bill[], options: {excludePayments : boolean, billPeriod: Date, billPeriodDuration: "Month" | "Fortnight" | "Week"}): Bill[] {
    let response: Bill[] = []
    let billsArray : Bill[] = []

    if (options?.excludePayments == null || !options.excludePayments) {
        let periodFromBaseDate = new Date(options.billPeriod)
        periodFromBaseDate.setDate(periodFromBaseDate.getDate() + daysPerPeriod(options.billPeriodDuration))

        const paymentAmount = AmountPerPeriodForBills(bills, options.billPeriodDuration)

        let payments : Bill = {
            id: PaymentLogID,
            name: "Payment",
            frequency: options.billPeriodDuration,
            amount: -paymentAmount,
            nextDue: periodFromBaseDate,
            periodAmount: -paymentAmount
        }
        billsArray = [...bills, payments]
    } else {
        billsArray = [...bills]
    }

    billsArray.forEach(b => response.push(...annualReserveForBill(b)))

    response.sort((a, b) => {
        return a.nextDue < b.nextDue ? -1 : a.nextDue > b.nextDue ? 1 : 
                a.amount < b.amount ? -1 : a.amount > b.amount ? 1 : 0
    })

    let balance = 0

    return response.map(b => {
        balance = balance - b.amount
        return {...b, balanceAfter: balance}
    })
}