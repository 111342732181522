import { Axios } from "axios";

export const api = (accessToken?: string) => {
    return new Axios({
    baseURL: "https://cpnoge9wr8.execute-api.us-east-1.amazonaws.com",
    headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json"
    },
    transformResponse: (data) => {
        if (data != null && Object.keys(data).length > 0) {
            return JSON.parse(data)
        }
        return
    },
    transformRequest: (data) => {
        return JSON.stringify(data)
    }
})
    }