export function compareToNow(e: Date) {
    const today = new Date();
    const eventDate = new Date(e);
    const isOverdue = eventDate.getDate() < today.getDate() &&
        eventDate.getMonth() <= today.getMonth() &&
        eventDate.getFullYear() <= today.getFullYear();
    const isToday = eventDate.getDate() === today.getDate() &&
        eventDate.getMonth() === today.getMonth() &&
        eventDate.getFullYear() === today.getFullYear();
    return { isOverdue, isToday }
}