import { Bill } from "../interfaces/Models";

// RequiredBalanceForBills calculates the minimum balance at the BaseDate for a given array of bills
export function RequiredBalanceForBills(bills: Bill[], billPeriod: Date): number {
    let yearFromBaseDate = new Date(billPeriod);
    yearFromBaseDate.setFullYear(yearFromBaseDate.getFullYear() + 1);

    let balance = 0;
    let minBalance = 0;
    bills
        .filter(b => {
            return b.nextDue.getTime() <= yearFromBaseDate.getTime();
        })
        .forEach(b => {
            balance = balance - b.amount;
            if (balance <= minBalance) {
                minBalance = balance;
            }
        });

    return -minBalance;
}
